import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import BodyCopy from '../components/atoms/body-copy'
import Lists from '../components/atoms/lists'


const ContactUsPage = () => (
  <Layout hideSecureForm>
    <SEO title='Contact Us' />
    <Heading 
        title='Contact Us'
        weight='light'
    />
    <BodyCopy
      copy={
        "Contact us: <a href='mailto:media@sunshine-offers.com'>media@sunshine-offers.com</a>"
      }
    />

    
  </Layout>
)

export default ContactUsPage
